window.configCrypt =
    {
  "VIDI_TOKEN_ADDRESS": "0x55f4CA5C806Cb9a27e8e3D30B7EF21d52152FAa8",
  "LOCKER_ADDRESS": "0xCBc4D0b1F99f4374FA25EbE999f8108dc572b52a",
  "PRESALE_ADDRESS": "0x3340A5bbeb885951FACB3b38384d6d27C253AAD2",
  "USDT_ADDRESS": "0x55d398326f99059ff775485246999027b3197955",
  "RPC_NODES": [
    "https://bsc-dataseed.binance.org/"
  ],
  "WITHDRAW_GAS_VALUE": "300000",
  "BUY_LOCATION_GAS_VALUE": "500000",
  "CHAIN_ID": 56,
  "BLOCKS_TO_READ": 4500,
  "SEED_PRICE_USDT": "20000000000000000",
  "PRIVATE_PRICE_USDT": "10000000000000000",
  "SEED_AMOUNT": 1200000,
  "PRIVATE_AMOUNT": 12500,
    };